html {
    background-color: #f0f0f0;
    padding-bottom: 0 !important;
}

body {
    font-family: "Source Sans Pro";
    padding: 0;
    margin: 0;
    background-color: transparent;
}

:focus {
  outline: none;
}

nav {
  top: 0;
}

hr {
  /*background: linear-gradient(90deg,#807e7c 25%,rgba(128,126,124,0) 0) 0 100%/4px 1px repeat-x !important;*/
  border-color: #c2c2c2 !important;
  border-style: dashed !important;
}

.custom-border-right {
  /*background: linear-gradient(90deg,#807e7c 25%,rgba(128,126,124,0) 0) 0 100%/4px 1px repeat-x !important;*/
  border-right: 1px dashed #c2c2c2 !important;

}

.w-settings-update-automatically.cust-hide {
  max-height: 0 !important;
  transition: all .5s !important;
  overflow: hidden !important;
}
.w-settings-update-automatically {
    max-height: 500px !important;
    transition: max-height 0.25s ease-in !important;
}

.cart {
  display:block;
  width:100%;
  left:0;
  text-align:center;
  line-height:30px;
  float:left;
  background:#6f9d39;
  color:#fff;
  position:fixed;
  z-index:999;
  bottom:-60px;  
  height: 60px;
  line-height: 60px;
  transition: .5s all;
  max-width: 100% !important;
}

.cart.active {
  bottom: 0;
  transition: .5s all;
}

.w-inner-content {
    margin: inherit;
    float: left;                
}

.da-sidebar {
    float: left;
}

.custom-masonry {
  width: 100%;
}



/*IMPORTANT FOR NON-ARTICLE PREVIEW PAGE.*/
.w-inner-content {
    margin: auto;
    float: none;                
}

body .w-content {
    max-width: inherit;
    margin: inherit;
    float: left !important;                
}






@media only screen and (min-width: 1421px) {
  .w-content {
    margin: auto !important;
    float:none !important;
  }
}

  .custom-masonry {
    display: block !important;
  }

@media only screen and (max-width: 1420px) {

    .da-sidebar {
        max-width: 100% !important;
        width: 100% !important;

        width: 100% !important;
        margin: auto;
        margin-left: auto !important;
        float: none;
        padding-left: 50px;

        margin: auto !important;
    }

    .w-da {
        float: left;
        margin-right: 20px;
    }

  .custom-masonry {
    display: block;
    width: 100%;
    float: left;
    flex-wrap: inherit;
    margin: 0;
    line-height: 0;
    -webkit-column-count: 3;
    -webkit-column-gap: 0px;
    -moz-column-count: 3;
    -moz-column-gap: 0px;
    column-count: 3;
    column-gap: 0px;
    padding: 0;    
  }

  .custom-masonry img {
    width: 100%;
    /*padding: 10px !important;*/
  }

  .da-sidebar {
    width: 100% !important;
    max-width: 770px !important;
    max-width: 1130px !important;
    padding-left: 0 !important;
  }

}

@media only screen and (max-width: 1360px) {
  .custom-masonry {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }

  .da-sidebar {
    max-width: 760px !important;
  }

}

@media only screen and (max-width: 767px) {

  .cart {
    height: 130px;
    bottom: -130px;
  }


  .anzeige-disclaimer {
    text-align: center;
  }

  .custom-masonry {
    display: block;
    width: 100%;
    float: left;
    flex-wrap: inherit;
    margin: 0;
    line-height: 0;
    -webkit-column-count: 1;
    -webkit-column-gap: 0px;
    -moz-column-count: 1;
    -moz-column-gap: 0px;
    column-count: 1;
    column-gap: 0px;
    padding: 0;    
  }

  #share {
    margin: auto
  }

  .custom-border-right {
    border-right: 0 !important;
  }

  .hr-after-3,
  .hr-after-2 {
    display: none;
  }

  .w-content .w-inner-content{
    padding: 0 !important;
    margin: 0 !important;
    width: 100% !important;
  }

  .logo-magazine {
    float: none !important;
    margin-top: 20px;
    display: block;
    margin:auto;
  }

  .clubname-magazine {
    width: 100%;
    text-align: center;
    float: left;
    padding-bottom: 20px;
  }

}

@media only screen and (min-width: 768px) {
  .custom-border-right.row_nr_2 {
    border-right: 0 !important;
  }

  .hr-after-3,
  .hr-after-1 {
    display: none;
  }

}

@media only screen and (min-width: 991px) {
  .custom-border-right.row_nr_2 {
    border-right: 1px dashed #c2c2c2 !important;
  }  

  .custom-border-right {
    padding-right: 20px;
  }
  .custom-border-right.row_nr_2 {
    padding-right: 20px;
    padding-left: 20px;
  }
  .custom-border-right.row_nr_3 {
    padding-left: 20px;
    padding-right: 15px;
  }

  .hr-after-3 {
    display: block;
  }

  .hr-after-2,
  .hr-after-1 {
    display: none;
  }

  .custom-border-right.row_nr_3 {
    border-right: 0 !important;
  }
}

.show-preview-button .teaser-image {
  overflow: hidden;
  position: relative;
  transition: all .3s;
}

.show-preview-button:hover > .teaser-image .preview-button {
  transition: all .3s;
  top: 0;
}

.preview-button {
  height: 40px;
  top: -40px;
  transition: all .3s;
  width: 100%;
  background: rgba(255,255,255,0.7);  
  position: absolute;
  text-align: center;
  line-height: 40px;
  font-weight: 600;
}

.w-header {
  width: 100%;
  height: 500px;
  float: left;
  background-color: #f9f9f9;
  box-shadow: 0 0 6px #555;
  margin-bottom: 20px;
  background-size: cover !important;
  background-position: center center !important;
}

.w-content {
  float: left;
  width: 100%;
}

.w-content .w-inner-content {
  margin: auto;
  max-width: 998px;
  border: 1px solid #ccc;
  width: 90%;
  background: #fff;
  padding: 15px;
  box-sizing: border-box;
  margin-top: -150px;
}

.w-article-preview.featured-article-frontend .article-preview-image {
  height: auto;
  background-position: center center;
}

.form-group {
  width: 100%;
}

.w-article-preview.featured:after {
    position: absolute;
    content: "\2605";
    width: 40px;
    height: 40px;
    font-size: 2.2em;
    right: -6px;
    top: -27px;
    color: #FFC107;
    text-shadow: -2px 4px 7px #666;
}

.w-article-preview a,
.w-article-preview p,
.w-article-preview h2 {
  color:#000;
  text-decoration:none;
}

.article-preview-image span.click-span,
.w-article-preview .article-preview-image span.click-span,
.article-preview-image span.recipe-span,
.w-article-preview .article-preview-image span.recipe-span,
.article-preview-image span.video-span,
.w-article-preview .article-preview-image span.video-span {
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 6px 12px;
    background: #b11c1c;
    margin: 10px;
    border-radius: 4px;
    font-weight: 600;
    color:#fff;
    border: 1px solid #fff;
    height: auto;
}

.article-preview-image span.click-span,
.w-article-preview .article-preview-image span.click-span {
  left: 0;
  right: auto;
  background: #fff;
  color: #333;
}

.article-preview-image span.recipe-span,
.w-article-preview .article-preview-image span.recipe-span {
  background: #2d9c27;
}


.w-article-preview .article-preview-image {
  width:100%;
  height:160px;
  background-size:cover;
  background-position:center bottom;
  position: relative;  
  padding-top: 56.25% !important; /* ratio:16:9 */
  height: auto;

}

.w-article-preview .article-go {
  color:#ff0000;
    margin-top: 10px;
    float: left;
    width: 100%;
    margin-bottom: 2px;  
}

.w-article-preview .article-preview-title:hover {
  color: #8B8B8B;
}

.w-article-preview .article-preview-title {
  font-size:1.3em !important;
  margin-top:12px !important;
  font-weight:600 !important;
  text-decoration:none !important;
}

.w-article-preview .article-teaser {
  font-size:1.05em;
  font-family:'Times New Roman';
  margin-bottom: 0;
  text-decoration:none;
}

.cust-shadow {
    position: absolute;
    width: 100%;
    height: 50px;
    background: white;
    content: '';
    left: 0;
    top: -52px;
    opacity: 0.8;
    filter: blur(10px);
    z-index: 999;
}

.article-preview-unlock-window {
  position: absolute !important;
  bottom: 0 !important;
  display: none;
  left: 0 !important;
  right: 0 !important;
  min-height: 100px !important;
  background: rgba(255,255,255,1);
  padding: 20px;
  text-align: center;
  box-shadow: 0 -1px 10px #ccc;  
  width: 95%;
  margin: auto;
  margin-bottom: 30px;    
}

.article-preview-unlock-window.active {
  display: block;
}



/* HTML ELEMENTS */
.container-checkboxes {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container-checkboxes input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  margin-top: 5px;
  margin-left: 4px;  
}

/* On mouse-over, add a grey background color */
.container-checkboxes:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container-checkboxes input:checked ~ .checkmark {
  background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container-checkboxes input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container-checkboxes .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}




.disabled,
.btn {
  transition: all .3s;
}

#choose-articles.disabled {
  pointer-events: none;
}













/* ARTICLES PAGE */
.span-published .check {
  display: inline;
  font-size: 1.3em;
  color: #8bc34a;  
}
.span-published .cross {
  color: #c90000;
  display: none;
}

.span-published.nicht .check {
  display: none;
}

.span-published.nicht .cross {
  color: #c90000;
  display: inline;
  font-size: 1.3em;
}

#w-video_article {
  
}









.upload-btn {
  opacity: 0.6;
}
.upload-btn.chosen {
  opacity: 1;
}









/*FOOTER*/
.footer {
  background: #343a40 !important;
  color: white;
  width: 100% !important;
  float: left !important;
}
.footer .links ul {
  list-style-type: none;
}
.footer .links li a {
  color: white;
  -webkit-transition: color .2s;
  transition: color .2s;
}
.footer .links li a:hover {
  text-decoration: none;
  color: #4180CB;
}
.footer .about-company i {
  font-size: 25px;
}
.footer .about-company a {
  color: white;
  -webkit-transition: color .2s;
  transition: color .2s;
}
.footer .about-company a:hover {
  color: #4180CB;
}
.footer .location i {
  font-size: 18px;
}
.footer .copyright p {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}